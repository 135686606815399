/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@common_typography';
import classNames from 'classnames';
import { useTranslation } from '@i18n';
import useStyles from './style';

const RadioItem = (props) => {
    const styles = useStyles();
    const {
        value, label, className, hidden,
    } = props;
    const customStyle = classNames(styles.radioContainer, className, hidden ? 'hide' : '');
    return (
        <FormControlLabel
            value={value || ''}
            control={<Radio color="default" size="small" />}
            label={label.replace(/_/g, ' ') || ''}
            className={customStyle}
        />
    );
};

// Inspired by blueprintjs
function CustomRadio({
    valueData = [],
    onChange = () => {},
    value = '',
    name = 'radio',
    ariaLabel = 'radio',
    label = '',
    noLabel,
    CustomItem,
    className = {},
    classContainer = {},
    classItem = {},
    flex = 'column',
    error = false,
    errorMessage = '',
    propsItem = {},
    disabled = false,
    CustomLabel,
    toggleShowMore = false,
    showMoreLimit = 5,
}) {
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    const [expanded, setExpanded] = React.useState(false);

    const rootStyle = classNames(styles.root, className);
    const containerStyle = classNames(styles[flex], classContainer, styles.error);

    const handleChange = (event) => {
        !disabled && onChange(event.target.value);
    };

    const handleChangeCustom = (val) => {
        !disabled && onChange(val);
    };
    return (
        <div className={rootStyle}>
            {!noLabel
                ? CustomLabel ? (
                    <CustomLabel />
                ) : (
                    <Typography variant="label" type="bold" letter="uppercase">
                        {label.replace(/_/g, ' ')}
                    </Typography>
                ) : null}

            <RadioGroup
                aria-label={ariaLabel}
                name={name}
                value={value}
                onChange={handleChange}
                classes={{
                    root: containerStyle,
                }}
            >
                {valueData.map((item, index) => (CustomItem ? (
                    <CustomItem
                        key={index}
                        {...item}
                        selected={JSON.stringify(value) === JSON.stringify(item.value)}
                        onChange={handleChangeCustom}
                        className={classItem}
                        {...propsItem}
                    />
                ) : (
                    <RadioItem
                        key={index}
                        {...item}
                        {...propsItem}
                        className={classItem}
                        hidden={toggleShowMore && !expanded && (index > showMoreLimit - 1)}
                    />
                )))}
            </RadioGroup>
            {
                (toggleShowMore && valueData.length > showMoreLimit) && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                        onClick={() => setExpanded(!expanded)}
                        className={styles.showMore}
                    >
                        {
                            expanded ? (
                                <>
                                    <Typography
                                        variant="span"
                                        type="bold"
                                        className={classNames(styles.showMoreText, 'clear-margin-padding')}
                                    >
                                        {t('common:button:showLess')}
                                    </Typography>
                                    <ExpandLessIcon color="inherit" fontSize="inherit" />
                                </>
                            ) : (
                                <>
                                    <Typography
                                        variant="span"
                                        type="bold"
                                        className={classNames(styles.showMoreText, 'clear-margin-padding')}
                                    >
                                        {t('common:button:showMore')}
                                    </Typography>
                                    <ExpandMoreIcon color="inherit" fontSize="inherit" />
                                </>
                            )
                        }
                    </div>
                )
            }
            {
                error && (
                    <Typography variant="p" color="red">
                        {errorMessage}
                    </Typography>
                )
            }
        </div>
    );
}

export default CustomRadio;
